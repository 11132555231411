export const MAX_INDENT = 9;

export const INDENT_LEVELS = [];
for (let i = 0; i < 9; i++) {
  INDENT_LEVELS.push({ key: i, title: `Level ${i + 1}` });
}

const defaultWrap = { key: 'none', title: 'Default' };

export const INDENT_WRAPS = [...INDENT_LEVELS];

export const INDENT_TYPES = {
  NONE: 'none',
  INDENT_FIRST_LINE: 'indentFirstLine',
  INDENT_HANGING: 'indentHanging',
};

export default class IndentationStyle {
  constructor({ level = 1, type = INDENT_TYPES.NONE, wrap = 0, override = false, forcedLevel = 'none' }) {
    this.level = level;
    this.type = type;
    this.wrap = wrap;
    this.override = override;
    this.forcedLevel = forcedLevel;
  }
}
