import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import { Combobox } from 'react-widgets';

import { classNamePrefixer } from '@core/utils/Generators';

import { Icon } from '@components/dmp';

const MIN_SEARCHABLE = 8;
const NEW_ITEM_KEY = '__NEW_ITEM__';

const noneSelectedOption = { label: 'None selected', value: null };

const CustomCombobox = React.forwardRef(
  (
    {
      onChange,
      className,
      type,
      disabled,
      width,
      clearable,
      searchable,
      options,
      placeholder,
      customMessages,
      onClear,
      size,
      block,
      defaultValue,
      canCreate,
      createLabel,
      enableEmpty,
      open: externalOpen,
      ...rest
    },
    ref
  ) => {
    // For now, do NOT use enableEmpty, only allow clearing at all time, which will act as an "enableEmpty" through the X vs selecting an option.
    enableEmpty = false;

    const isTempalteSelector = placeholder === 'Select a template';

    const [value, setValue] = React.useState(defaultValue);
    //if (isTempalteSelector) console.log('<Dropdown>.useState(value)', defaultValue, value);
    const [isDropdownOpen, setIsDropdownOpen] = React.useState(externalOpen || false);

    const cl = classNamePrefixer('combobox');
    const showClear = value && clearable && !disabled && type !== 'search';
    const classNames = cx(
      cl(),
      className,
      cl(`type-${type}`),
      cl(`size-${size}`),
      { [cl('block')]: block },
      { clearable: showClear }
    );

    // Disable this for now, they will ALL be searchable and ALL behave the exact same way.
    /* if (options.length < MIN_SEARCHABLE) {
      searchable = false;
    } */

    React.useEffect(() => {
      // Synchronize internal state with external prop
      if (externalOpen !== undefined) {
        setIsDropdownOpen(externalOpen);
      }
    }, [externalOpen]);

    // Likely not needed, we always use it in React.useState() above
    React.useEffect(() => {
      // Update internal value state when defaultValue prop changes
      //if (isTempalteSelector) console.log('React.useEffect of defaultValue trigger');
      if (defaultValue !== undefined) {
        //if (isTempalteSelector) console.log('React.useEffect of defaultValue', 'set value to', defaultValue);
        setValue(defaultValue);
      }
    }, [defaultValue]);

    /* React.useEffect(() => {
      // If the defaultValue is null and clearable and enableEmpty, set to noneSelectedOption
      // Disable this for now, they will ALL be searchable and ALL behave the exact same way.
      if (clearable && enableEmpty && defaultValue === null) {
        setValue(noneSelectedOption);
      } else
      if (defaultValue !== undefined) {
        setValue(defaultValue);
      }
    }, [defaultValue, clearable]); */

    const modifiedOptions = React.useMemo(() => {
      let modified = [...options];

      // Disable this for now, they will ALL be searchable and ALL behave the exact same way.
      /* if (options.length < MIN_SEARCHABLE && clearable && enableEmpty) {
        modified = [noneSelectedOption, ...modified];
      } */

      if (canCreate) {
        modified.push({ label: createLabel, value: NEW_ITEM_KEY, isCreateNew: true });
      }

      return modified;
    }, [options, clearable, canCreate, createLabel]);

    const handleSelect = (selectedItem) => {
      if (selectedItem && selectedItem.value === null) {
        setValue(noneSelectedOption);
      } else {
        setValue(selectedItem);
      }
      onChange(selectedItem);
    };

    const handleClear = (e) => {
      e.stopPropagation();

      setValue(null);
      onChange(null);

      if (onClear) onClear();
    };

    const handleFocus = (e) => {
      if (!searchable || isDropdownOpen === false) {
        setIsDropdownOpen(true);
      }
    };

    const handleToggle = (isOpen) => {
      // Update internal state only if external control is not defined
      if (externalOpen === undefined) {
        setIsDropdownOpen(isOpen);
      }
    };

    const filterOptions = (item, searchTerm) => {
      let term;
      if (typeof searchTerm === 'string') {
        term = searchTerm.toLowerCase();
      } else if (searchTerm && searchTerm.label) {
        term = searchTerm.label.toLowerCase();
      } else {
        return true;
      }

      return (
        item.label.toLowerCase().includes(term) || (item.description && item.description.toLowerCase().includes(term))
      );
    };

    const inputProps = {
      onClick: handleFocus,
      readOnly: !searchable,
    };

    const DropDownOption = ({ item }) => {
      const itemClass = item.value === null ? 'item no-option-item' : 'item';

      if (item.isCreateNew) {
        return (
          <div className="item new-item">
            <Icon className="dropdown-icon" name="plus2" />
            <span>{item.label}</span>
          </div>
        );
      }

      return <div className={`${itemClass}`}>{item.label}</div>;
    };

    const SearchOption = ({ item }) => (
      <div className="search-option-container">
        <div className="search-option-content">
          <span className="search-option-title" data-cy="search-option-title">
            {item.label}
          </span>
          <div className="search-option-description">{item.description}</div>
        </div>
        <div className="search-option-chevron">
          <Icon name="chevronRight" size="large" />
        </div>
      </div>
    );

    const IconOptions = () => {
      if (type === 'search') {
        if (value && clearable && !disabled) {
          return <Icon name="close" onClick={handleClear} size={size} />;
        } else {
          return <Icon name="search" size={size} />;
        }
      } else {
        return <Icon name="chevronDown" size={size === 'small' ? 'default' : 'large'} />;
      }
    };

    return (
      <div className={classNames} style={{ width }}>
        <Combobox
          data={modifiedOptions}
          value={value}
          defaultValue={defaultValue}
          textField="label"
          onSelect={handleSelect}
          inputProps={inputProps}
          open={isDropdownOpen}
          placeholder={placeholder}
          onToggle={handleToggle}
          onChange={searchable ? (value) => setValue(value) : undefined}
          disabled={disabled}
          filter={searchable ? filterOptions : false}
          renderListItem={type === 'search' ? SearchOption : DropDownOption}
          ref={ref}
          busy={true}
          className={isDropdownOpen ? 'dropdown-open' : 'dropdown-closed'}
          busySpinner={IconOptions()}
          messages={customMessages}
          {...rest}
        />

        {value && clearable && !disabled && type !== 'search' && (
          <button className="clear-button" onClick={handleClear} data-cy="combobox-clear-button">
            <Icon name="close" size={size === 'small' ? 'default' : 'large'} />
          </button>
        )}
      </div>
    );
  }
);

CustomCombobox.propTypes = {
  disabled: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  clearable: PropTypes.bool,
  searchable: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    })
  ).isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.oneOf(['dropdown', 'search']),
  onChange: PropTypes.func,
  customMessages: PropTypes.shape({
    emptyList: PropTypes.string,
    emptyFilter: PropTypes.string,
  }),
  onClear: PropTypes.func,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  canCreate: PropTypes.bool,
  createLabel: PropTypes.string,
};

CustomCombobox.defaultProps = {
  disabled: false,
  clearable: true,
  searchable: true,
  placeholder: 'Select...',
  width: null,
  type: 'dropdown',
  onChange: () => {},
  customMessages: {
    emptyList: 'No items available.',
    emptyFilter: 'No results found for your search.',
  },
  size: 'medium',
  canCreate: false,
  createLabel: 'New Item',
  enableEmpty: false,
};

export default CustomCombobox;
