import IconExplainer from '@assets/svg/15x15-explainer.svg';
import IconDotsHorizontal from '@assets/svg/20x20-3dots-horizontal.svg';
import IconDots from '@assets/svg/20x20-3dots-vertical.svg';
import IconAudit from '@assets/svg/20x20-activity.svg';
import IconAIAuto from '@assets/svg/20x20-ai-auto.svg';
import IconAI from '@assets/svg/20x20-ai.svg';
import IconTimeline from '@assets/svg/20x20-ai.svg';
import IconSectionAppendix from '@assets/svg/20x20-appendix.svg';
import IconDrop from '@assets/svg/20x20-arrow2down.svg';
import IconArrowDown from '@assets/svg/20x20-arrowdown.svg';
import IconArrowUp from '@assets/svg/20x20-arrowup.svg';
import IconAttachment from '@assets/svg/20x20-attachment.svg';
import IconBellSilent from '@assets/svg/20x20-bell-silent.svg';
import IconBell from '@assets/svg/20x20-bell.svg';
import IconBlock from '@assets/svg/20x20-block.svg';
import IconSectionBody from '@assets/svg/20x20-body.svg';
import IconBundle from '@assets/svg/20x20-bundle.svg';
import IconCalculator from '@assets/svg/20x20-calculator.svg';
import IconCalendar from '@assets/svg/20x20-calendar.svg';
import IconCaption from '@assets/svg/20x20-caption.svg';
import IconCheck from '@assets/svg/20x20-check.svg';
import IconChevronDown from '@assets/svg/20x20-chevron-down.svg';
import IconChevronUp from '@assets/svg/20x20-chevron-up.svg';
import IconChevronLeft from '@assets/svg/20x20-chevronleft.svg';
import IconFilterCollapse from '@assets/svg/20x20-chevronleft.svg';
import IconChevronRight from '@assets/svg/20x20-chevronright.svg';
import IconCircleCheck from '@assets/svg/20x20-circle-check.svg';
import IconClose from '@assets/svg/20x20-close.svg';
import IconColumns from '@assets/svg/20x20-columns.svg';
import IconComments from '@assets/svg/20x20-comments.svg';
import IconConditional from '@assets/svg/20x20-conditionals.svg';
import IconConnect from '@assets/svg/20x20-connect.svg';
import IconContracts from '@assets/svg/20x20-contracts.svg';
import IconDashboard from '@assets/svg/20x20-dashboard.svg';
import IconDocScan from '@assets/svg/20x20-doc-scan.svg';
import IconDocument from '@assets/svg/20x20-document-blank.svg';
import IconError from '@assets/svg/20x20-document-error.svg';
import IconDocx from '@assets/svg/20x20-docx.svg';
import IconDraft from '@assets/svg/20x20-draft.svg';
import IconRedlining from '@assets/svg/20x20-draft.svg';
import IconDragndrop from '@assets/svg/20x20-dragndrop.svg';
import IconDuplicate from '@assets/svg/20x20-duplicate.svg';
import IconCopy from '@assets/svg/20x20-duplicate.svg';
import IconElements from '@assets/svg/20x20-elements.svg';
import IconEmail from '@assets/svg/20x20-email.svg';
import IconExclamation from '@assets/svg/20x20-exclamation-round.svg';
import IconExport from '@assets/svg/20x20-export.svg';
import IconFieldsEdit from '@assets/svg/20x20-fields-edit.svg';
import IconFieldsSecret from '@assets/svg/20x20-fields-secret.svg';
// Add FV-specific icons for dotvine here. Hopefully we don't need too many more of these...
import FV_IconDocx from '@assets/svg/20x20-file-docx.svg';
import IconImage from '@assets/svg/20x20-file-image.svg';
import IconJson from '@assets/svg/20x20-file-json.svg';
import FV_IconPdf from '@assets/svg/20x20-file-pdf.svg';
import IconSpreadsheet from '@assets/svg/20x20-file-spreadsheet.svg';
import IconTxt from '@assets/svg/20x20-file-txt.svg';
import IconFilterExpand from '@assets/svg/20x20-filter.svg';
import IconFlowMode from '@assets/svg/20x20-flow.svg';
import FV_IconFolderOutline from '@assets/svg/20x20-folder-outline.svg';
import IconBold from '@assets/svg/20x20-font-bold.svg';
import IconItalic from '@assets/svg/20x20-font-italic.svg';
import IconUnderline from '@assets/svg/20x20-font-underline.svg';
import IconGlobal from '@assets/svg/20x20-global.svg';
import IconSectionHeader from '@assets/svg/20x20-header.svg';
import IconThumbsDown from '@assets/svg/20x20-icon-thumbsdown.svg';
import IconThumbsUp from '@assets/svg/20x20-icon-thumbsup.svg';
import IconImport from '@assets/svg/20x20-import.svg';
import IconIndent from '@assets/svg/20x20-indent.svg';
import IconInfo from '@assets/svg/20x20-info.svg';
import IconIntegrations from '@assets/svg/20x20-integrations.svg';
import IconLink from '@assets/svg/20x20-link.svg';
import IconList from '@assets/svg/20x20-list.svg';
import IconListStyle from '@assets/svg/20x20-liststyle.svg';
import IconLockClosed from '@assets/svg/20x20-lock-closed.svg';
import IconLockGuest from '@assets/svg/20x20-lock-guest.svg';
import IconLockOpen from '@assets/svg/20x20-lock-open.svg';
import IconLogoFilevine from '@assets/svg/20x20-logo-filevine.svg';
import IconLogoHubSpot from '@assets/svg/20x20-logo-hubspot.svg';
import IconLogoSalesforce from '@assets/svg/20x20-logo-salesforce.svg';
import IconLogoWebhook from '@assets/svg/20x20-logo-webhook.svg';
import IconMinus from '@assets/svg/20x20-minus.svg';
import IconNative from '@assets/svg/20x20-native.svg';
import IconNope from '@assets/svg/20x20-no-symbol.svg';
import IconNoLink from '@assets/svg/20x20-nolink.svg';
import IconNumbering from '@assets/svg/20x20-numbering.svg';
import IconOutdent from '@assets/svg/20x20-outdent.svg';
import IconOverview from '@assets/svg/20x20-overview.svg';
import IconPageBreak from '@assets/svg/20x20-page-break.svg';
import IconParties from '@assets/svg/20x20-party.svg';
import IconPayment from '@assets/svg/20x20-payment.svg';
import IconPdf from '@assets/svg/20x20-pdf.svg';
import IconPlus2 from '@assets/svg/20x20-plus2.svg';
import IconPlus from '@assets/svg/20x20-plus.svg';
import IconPointer from '@assets/svg/20x20-pointer.svg';
import IconPreview from '@assets/svg/20x20-preview.svg';
import IconProfile from '@assets/svg/20x20-profile.svg';
import IconQuestionmark from '@assets/svg/20x20-question-mark.svg';
import IconCheckpoint from '@assets/svg/20x20-readycheck.svg';
import IconRef from '@assets/svg/20x20-reference.svg';
import IconRepeater from '@assets/svg/20x20-repeater.svg';
import IconScope from '@assets/svg/20x20-scope.svg';
import IconSearch from '@assets/svg/20x20-search.svg';
import IconSecret from '@assets/svg/20x20-secret.svg';
import IconSettingsCog from '@assets/svg/20x20-settings-cog.svg';
import IconSettings from '@assets/svg/20x20-settings.svg';
import IconSignatureScript from '@assets/svg/20x20-signature-script.svg';
import IconDeal from '@assets/svg/20x20-signature.svg';
import IconSectionSignature from '@assets/svg/20x20-signature.svg';
import IconSwatch from '@assets/svg/20x20-swatch.svg';
import IconTag from '@assets/svg/20x20-tag.svg';
import IconTemplate from '@assets/svg/20x20-templates.svg';
// Draft toolbar imports
import IconAlignCenter from '@assets/svg/20x20-textalign-center.svg';
import IconAlignJustify from '@assets/svg/20x20-textalign-justify.svg';
import IconAlignLeft from '@assets/svg/20x20-textalign-left.svg';
import IconAlignRight from '@assets/svg/20x20-textalign-right.svg';
import IconTrash from '@assets/svg/20x20-trash.svg';
import IconUndo from '@assets/svg/20x20-undo.svg';
import IconUpload from '@assets/svg/20x20-upload.svg';
import IconSharing from '@assets/svg/20x20-users.svg';
import IconUsers from '@assets/svg/20x20-users.svg';
import IconVariable from '@assets/svg/20x20-variable.svg';
import IconVersions from '@assets/svg/20x20-versions.svg';
import IconVine from '@assets/svg/20x20-vine.svg';
import IconExcluded from '@assets/svg/20x20-visibility-off.svg';
import IconIncluded from '@assets/svg/20x20-visibility-on.svg';
import IconZoomIn from '@assets/svg/20x20-zoom-in.svg';
import IconZoomOut from '@assets/svg/20x20-zoom-out.svg';
import IconCheckSolid from '@assets/svg/check-solid.svg';
import IconChevronSmall from '@assets/svg/chevron-small-down.svg';
import IconCloseTiny from '@assets/svg/close-dark-tiny.svg';
import IconCloseSolid from '@assets/svg/close-solid.svg';
import IconExclamationSolid from '@assets/svg/exclamation-solid.svg';
import IconPdfExport from '@assets/svg/fv-pdf.svg';
import IconCheckGreen from '@assets/svg/icon-check-green.svg';
import IconClause from '@assets/svg/icon-clause.svg';
import IconEdit from '@assets/svg/icon-edit.svg';
import IconIndentFirstLine from '@assets/svg/icon-indent-firstline.svg';
import IconIndentHanging from '@assets/svg/icon-indent-hanging.svg';
import IconIndentNone from '@assets/svg/icon-indent-none.svg';
import IconLens from '@assets/svg/icon-lens.svg';
import IconInfoSolid from '@assets/svg/info-solid.svg';
import IconOutlineLogo from '@assets/svg/logo-outline-black.svg';
import IconOverride from '@assets/svg/override-icon.svg';
import IconSyncDisabled from '@assets/svg/sync-disabled.svg';
import IconSyncEnabled from '@assets/svg/sync-enabled.svg';
import IconHamburger from '@assets/svg/topbar-mobile-hamburger.svg';

const COLOR_ICONS = {
  gray: IconSwatch,
  blue: IconSwatch,
  yellow: IconSwatch,
  red: IconSwatch,
  green: IconSwatch,
  purple: IconSwatch,
};

// Icon definitions
const ICONS = {
  ai: IconAI,
  aiAuto: IconAIAuto,
  aiAuto: IconAIAuto,
  alignCenter: IconAlignCenter,
  alignJustify: IconAlignJustify,
  alignLeft: IconAlignLeft,
  alignRight: IconAlignRight,
  arrowDown: IconArrowDown,
  arrowUp: IconArrowUp,
  attachment: IconAttachment,
  audit: IconAudit,
  bell: IconBell,
  bellSilent: IconBellSilent,
  block: IconBlock,
  bold: IconBold,
  bundle: IconBundle,
  calculator: IconCalculator,
  calendar: IconCalendar,
  caption: IconCaption,
  check: IconCheck,
  checkGreen: IconCheckGreen,
  checkpoint: IconCheckpoint,
  checkSolid: IconCheckSolid,
  chevronDown: IconChevronDown,
  chevronLeft: IconChevronLeft,
  chevronRight: IconChevronRight,
  chevronSmall: IconChevronSmall,
  chevronUp: IconChevronUp,
  circleCheck: IconCircleCheck,
  clause: IconClause,
  close: IconClose,
  closeSolid: IconCloseSolid,
  closeTiny: IconCloseTiny,
  columns: IconColumns,
  comments: IconComments,
  conditional: IconConditional,
  connect: IconConnect,
  contracts: IconContracts,
  copy: IconCopy,
  dashboard: IconDashboard,
  deal: IconDeal,
  docScan: IconDocScan,
  document: IconDocument,
  docx: IconDocx,
  dots: IconDots,
  dotsHorizontal: IconDotsHorizontal,
  draft: IconDraft,
  dragndrop: IconDragndrop,
  drop: IconDrop,
  duplicate: IconDuplicate,
  edit: IconEdit,
  elements: IconElements,
  email: IconEmail,
  error: IconError,
  exclamation: IconExclamation,
  exclamationSolid: IconExclamationSolid,
  excluded: IconExcluded,
  explainer: IconExplainer,
  export: IconExport,
  fieldsEdit: IconFieldsEdit,
  fieldsSecret: IconFieldsSecret,
  filterCollapse: IconFilterCollapse,
  filterExpand: IconFilterExpand,
  flowMode: IconFlowMode,
  fv_docx: FV_IconDocx,
  fv_pdf: FV_IconPdf,
  fv_pdfToDocs: FV_IconFolderOutline,
  fv_docxToDocs: FV_IconFolderOutline,
  global: IconGlobal,
  hamburger: IconHamburger,
  image: IconImage,
  import: IconImport,
  included: IconIncluded,
  indent: IconIndent,
  info: IconInfo,
  infoSolid: IconInfoSolid,
  integrations: IconIntegrations,
  italic: IconItalic,
  json: IconJson,
  lens: IconLens,
  link: IconLink,
  list: IconList,
  listStyle: IconListStyle,
  lockClosed: IconLockClosed,
  lockGuest: IconLockGuest,
  lockOpen: IconLockOpen,
  logoFilevine: IconLogoFilevine,
  logoHubspot: IconLogoHubSpot,
  logoSalesforce: IconLogoSalesforce,
  logoWebhook: IconLogoWebhook,
  minus: IconMinus,
  native: IconNative,
  noLink: IconNoLink,
  nope: IconNope,
  numbering: IconNumbering,
  outdent: IconOutdent,
  outlineLogo: IconOutlineLogo,
  override: IconOverride,
  overview: IconOverview,
  pageBreak: IconPageBreak,
  parties: IconParties,
  payment: IconPayment,
  pdf: IconPdf,
  pdfExport: IconPdfExport,
  plus: IconPlus,
  plus2: IconPlus2,
  pointer: IconPointer,
  preview: IconPreview,
  profile: IconProfile,
  questionmark: IconQuestionmark,
  redlining: IconRedlining,
  ref: IconRef,
  repeater: IconRepeater,
  scope: IconScope,
  search: IconSearch,
  secret: IconSecret,
  sectionAppendix: IconSectionAppendix,
  sectionBody: IconSectionBody,
  sectionHeader: IconSectionHeader,
  sectionSignature: IconSectionSignature,
  settings: IconSettings,
  settingsCog: IconSettingsCog,
  sharing: IconSharing,
  signatureScript: IconSignatureScript,
  spreadsheet: IconSpreadsheet,
  swatch: IconSwatch,
  syncDisabled: IconSyncDisabled,
  syncEnabled: IconSyncEnabled,
  tag: IconTag,
  template: IconTemplate,
  thumbsdown: IconThumbsDown,
  thumbsup: IconThumbsUp,
  timeline: IconTimeline,
  trash: IconTrash,
  txt: IconTxt,
  underline: IconUnderline,
  undo: IconUndo,
  upload: IconUpload,
  users: IconUsers,
  variable: IconVariable,
  versions: IconVersions,
  vine: IconVine,
  zoomIn: IconZoomIn,
  zoomOut: IconZoomOut,
  indentFirstLine: IconIndentFirstLine,
  indentHanging: IconIndentHanging,
  indentNone: IconIndentNone,

  ...COLOR_ICONS,
};

export { ICONS, COLOR_ICONS };
export default {};
